<template>
    <button
        class="hamburger"
        aria-labelledby="hoofdmenuLabel"
    >
        <span
            id="hoofdmenuLabel"
            class="sr-only"
        >
            Hoofdmenu
        </span>

        <span class="hamburger__bun">
            <span class="hamburger__bun__slice" />
            <span class="hamburger__bun__slice" />
            <span class="hamburger__bun__slice" />
        </span>
    </button>
</template>

<style lang="less" src="./BaseHamburger.less" scoped />
